const File = {
  description: null,
  document: { file: null, name: null },
  employee_id: null,
  id: null,
  name: null,
};
const EmployeeListData = {
  count: 0,
  results: [],
  ActiveEmployee: 0,
  TotalEmployee: 0,
  TotalManager: 0,
};
export { File, EmployeeListData };
