import moment from "moment";

const JobDetail = {
  Job_Title: "",
  Job_Type: "",
  Work_type: "",
  Employee_Type: "",
  Education: "",
  location: "",
  min_salary: "",
  max_salary: "",
  Deadline: "",
  Job_Requirement: "",
  Job_Description: "",
  currency:"",
  start_date:moment(new Date()).format("YYYY-MM-DD"),
};

const ApplicationDetail = {
  first_name: "",
  last_name: "",
  phone_number: "",
  email: "",
  Year_of_Experience: null,
  location: "",
  cv: null,
  country_code: null,
  application_status: "",
  job_id: "",
  current_salary: "",
  expected_salary: "",
  availability_for_interview: null,
  application_status: "pending",
  notice_period:null,
};

export { JobDetail, ApplicationDetail };
