import React, { useState, useEffect } from "react";
import { IoCalendarOutline } from "react-icons/io5";
import { jobIcon } from "../../../../../assets/images";
import { FaCircleArrowRight } from "react-icons/fa6";
import { fetchJobPosts } from "../../../../hooks/recruitment";
import { Labels } from "../../Sections";
import {
  getEmployeeType,
  getWorkType,
  getJobType,
  getWorkLocation,
} from "utils/getValuesFromTables";
import moment from "moment";

const Message = ({ message }) => {
  return (
    <div className="mt-5">
      <p className="text-center">{message}</p>
    </div>
  );
};

const JobDetails = ({ job }) => {
  if (job) {
    const employeeType = getEmployeeType(job.Employee_Type);
    const workType = getWorkType(job.Work_type);
    const workLocation = getWorkLocation(job.location);
    const jobType = getJobType(job.Job_Type);

    const formattedUpdatedAt = job?.updated_at
      ? moment(job?.updated_at).format("DD MMMM YYYY")
      : "N/A";
    const formattedDeadline = job?.Deadline
      ? moment(job?.Deadline).format("DD MMMM YYYY")
      : "N/A";

    console.log(formattedDeadline, formattedUpdatedAt);

    return (
      <div className="flex flex-col justify-between gap-y-12 text-baseGray ">
        <div className="flex justify-between">
          <div className="flex items-center gap-x-2">
            <img src={jobIcon} alt="Job Icon" />
            <div>
              <p className="text-base">{job?.id}</p>
              <h3 className="text-[20px] font-bold text-capitalize">
                {job?.Job_Title}
              </h3>
            </div>
          </div>
          <div className="text-base flex items-center gap-x-2">
            <IoCalendarOutline className="text-lg" />
            {`${formattedUpdatedAt} - ${formattedDeadline} `}
          </div>
        </div>
        <div className="flex justify-start items-center flex-wrap gap-3">
          <Labels
            label={job.status === "live" ? "Open" : "Close"}
            iconDot={true}
            iconColor={`${
              job.status === "live" ? "bg-green-500" : "bg-red-500"
            }`}
            backgroungColor={`${
              job.status === "live" ? "bg-green-100" : "bg-red-100"
            }`}
          />
          <Labels label={employeeType} />
          <Labels label={workType} />
          <Labels label={workLocation} />
          <Labels label={jobType} />
        </div>
      </div>
    );
  } else {
    return <Message message={"No job to display"} />;
  }
};

const Tabs = ({ activeTab, onTabChange, activeJobId, changeJobFilter }) => {
  const [jobs, setJobs] = useState([]);
  const [currentJob, setCurrentJob] = useState("");
  const tabs = ["All Candidates", "Jobs"];
  const handleTabChange = (tab) => {
    if (tab === 0) {
      changeJobFilter("");
    } else if (tab === 1) {
      setCurrentJob(0);
      if (jobs && jobs.length > 0) changeJobFilter(jobs[0]?.id);
    }
    onTabChange(tab);
  };

  const handleJobChange = () => {
    if (jobs && currentJob === jobs.length - 1) {
      setCurrentJob(0);
      changeJobFilter(jobs[0].id);
    } else {
      const newJobIndex = currentJob + 1;
      setCurrentJob(newJobIndex);
      changeJobFilter(jobs[newJobIndex]?.id);
    }
  };

  useEffect(() => {
    const loadJob = async () => {
      try {
        const jobData = await fetchJobPosts();
        if (jobData.results && jobData.results.length > 0) {
          const index = jobData.results.findIndex(
            (obj) => obj.id === activeJobId
          );
          setCurrentJob(index ?? 0);
          setJobs(jobData.results);
        }
      } catch (error) {
        console.error("Error fetching job:", error);
      }
    };
    loadJob();
  }, [activeJobId]);

  const tabContents = [
    <Message message={"Showing All Applications"} />,
    <JobDetails job={jobs[currentJob]} jobIcon={jobIcon} />,
  ];

  return (
    <div className="bg-white w-full rounded-[10px] py-2 px-4 mb-2 h-[100%]">
      <div className={`flex justify-between items-center border-b pb-2`}>
        <div className="flex justify-between w-full">
          <div className="flex space-x-4">
            {tabs.map((tab, index) => {
              return (
                <button
                  key={tab}
                  className={`py-1 px-2 ${
                    activeTab === index
                      ? "border-b-2 border-[#35B6E9] text-base"
                      : "text-gray-500"
                  }`}
                  onClick={() => handleTabChange(index)}
                >
                  {tab}
                </button>
              );
            })}
          </div>
          <div className="flex items-center gap-x-3">
            {activeTab === 1 && (
              <div
                className="text-[#47484C] flex items-center"
                role="button"
                onClick={() => {
                  handleJobChange();
                }}
              >
                <span className="mr-1">Change Job </span>
                <FaCircleArrowRight />
              </div>
            )}{" "}
            {/* <button className="p-2 rounded-md bg-black"><FaCircleArrowRight className="text-white" /></button> */}
          </div>
        </div>
      </div>
      <div className={`${tabContents ? "mt-4" : ""}`}>
        {tabContents ? tabContents[activeTab] : null}
      </div>
    </div>
  );
};

export default Tabs;
