"'use client'";

import React, { useEffect } from "react";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../../../src/@/components/ui/avatar";
import { Button } from "../../../../components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../src/@/components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../src/@/components/ui/table";
import {
  DollarSign,
  TrendingUp,
  Calendar,
  ArrowLeft,
  CalendarIcon,
  Filter,
} from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../src/@/components/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../../../src/@/components/ui/command";
import { format } from "date-fns";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  getEmployeePayrollById,
  getSalaryRevisionByPayrollId,
  getSalaryRevision,
  getEmployeeEarnAndDeduction,
} from "app/hooks/payroll";
import RevisedSalarySheet from "./RevisedSalarySheet";
import {
  DesignationName,
  EmployeeID,
  getExperience,
} from "utils/getValuesFromTables";
import { getEmployeeData } from "app/hooks/employee";
import {numberToWords} from "utils/renderValues.js";
import { PageLoader } from "components";


export default function EmployeeSalaryDetails() {
  const [date, setDate] = React.useState();
  const [payrollDetails, setPayrollDetails] = React.useState({});
  const [salaryRevisions, setSalaryRevisions] = React.useState([]);
  const [employeeData, setEmployeeData] = React.useState({});
  const [selectedRevision, setSelectedRevision] = React.useState(null);
  const [filterData, setFilterData] = React.useState({});
  const [approvedRevisions, setApprovedRevisions] = React.useState(0);
  const [pendingRevisions, setPendingRevisions] = React.useState(0);
  const [rejectedRevisions, setRejectedRevisions] = React.useState(0);
  const [lastIncrementDate, setLastIncrementDate] = React.useState(null);
  const [salaryBreakup, setSalaryBreakup] = React.useState([]);
  const [latestApprovedSalaryRevision, setLatestApprovedSalaryRevision] =
    React.useState({});
  const [loading, setLoading] = React.useState(true);

  const { id } = useParams();
  const location = useLocation();
  const employeeID = new URLSearchParams(location.search).get("employeeID");
  console.log("Loading...", loading)

  const navigate = useNavigate();

  const fetchData = async () => {
    setLoading(true);
    const empData = await getEmployeeData(employeeID);
    if (empData) {
      setEmployeeData(empData);
    }

    const response = await getEmployeePayrollById(id);
    if (response) {
      setPayrollDetails(response);
    }

    const salaryBreakup = await getEmployeeEarnAndDeduction({})
    if(salaryBreakup){
      setSalaryBreakup(salaryBreakup);
    }
    const salaryRevisionData = await getSalaryRevision({
      filterData,
    }); // hardcode for now filter not woking on Backend
    if (salaryRevisionData) {
      setSalaryRevisions(salaryRevisionData?.revision);
      setApprovedRevisions(salaryRevisionData?.Approved_revision);
      setPendingRevisions(salaryRevisionData?.Pending_Revision);
      setRejectedRevisions(salaryRevisionData?.Rejected_application);
      setLastIncrementDate(salaryRevisionData?.lastIncrementDate);
    }

    const approvedRevisions = salaryRevisionData?.revision?.filter(
      (revision) => revision.revision_status === "APPROVED"
    );

    const latestApprovedSalaryRevision =
      approvedRevisions.length > 0
        ? approvedRevisions.reduce((latest, current) =>
            new Date(current.last_revised_date) >
            new Date(latest.last_revised_date)
              ? current
              : latest
          )
        : {};

    setLatestApprovedSalaryRevision(latestApprovedSalaryRevision);
    setLoading(false)
  };
  useEffect(() => {
    filterData?.employee_payroll && fetchData();
  }, [filterData]);

  useEffect(() => {
    if (employeeID) {
      setFilterData({ employee_payroll: id });
    }
  }, [id]);

  const handleBack = () => {
    navigate(-1); // This will navigate to the previous page
  };
  const handleSalaryRevisionClicked = (revision) => {
    setSelectedRevision(revision);
  };
  const onClose = () => {
    setSelectedRevision(null);
    fetchData();
  };

  if(loading){
    return <PageLoader />;
  }

  return (
    <div className="container p-4 mx-auto">
      {selectedRevision && (
        <RevisedSalarySheet
          payrollID={id}
          state={"view"}
          selectedRevision={selectedRevision}
          onClose={onClose}
        />
      )}
      <div className="mb-4">
        <Button
          variant="ghost"
          onClick={handleBack}
          className="p-4 text-xl text-balance"
        >
          <ArrowLeft className="w-6 h-6 mr-2 bg-white rounded-lg shadow-sm" />
          Detail
        </Button>
      </div>
      <div className="grid grid-cols-1 gap-4 mb-4 md:grid-cols-3">
        <Card>
          <CardContent className="flex items-center pt-6 space-x-4">
            <Avatar className="w-20 h-20 ">
              <AvatarImage
                src={employeeData?.avatar}
                alt={`${employeeData?.first_name} ${employeeData?.last_name}`}
              />
              <AvatarFallback className="bg-plum-400">
                {`${employeeData?.first_name} ${employeeData?.last_name}`
                  ?.split(" ")
                  .map((n) => n[0])
                  .join("")}
              </AvatarFallback>
            </Avatar>
            <div>
              <p className="text-base text-black">
                <EmployeeID value={employeeID} />
              </p>
              <h2 className="text-2xl font-bold text-plum-900">
                {employeeData?.first_name} {employeeData?.last_name}
              </h2>
              <p className="text-base text-muted-foreground">
                <DesignationName value={employeeData?.department_position} />
              </p>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between pb-2 space-y-0">
            <CardTitle className="text-base font-medium text-black">
              Cost to Company
            </CardTitle>
            <DollarSign className="w-4 h-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold text-plum-900">
              {Number(salaryBreakup.totalEarnings)} AED
            </div>
            <p className="text-xs text-muted-foreground">
              {numberToWords(Number(salaryBreakup.totalEarnings))}
            </p>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between pb-2 space-y-0">
            <CardTitle className="text-base font-medium text-black">
              No. of increments
            </CardTitle>
            <TrendingUp className="w-4 h-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold text-plum-900">
              {approvedRevisions}
            </div>
            <p className="text-xs text-muted-foreground">
              Last increment {lastIncrementDate}
            </p>
          </CardContent>
        </Card>
      </div>

      <div className="grid grid-cols-1 gap-4 mb-4 md:grid-cols-3 h-[450px]">
        <Card className="h-fit">
          <CardHeader>
            <CardTitle className="text-plum-900">Salary BreakUp</CardTitle>
          </CardHeader>
          <CardContent>
            <h3 className="mb-4 text-lg font-semibold text-black">
              CTC Components
            </h3>
            <div className="flex flex-col gap-4">
              <div className="flex flex-row font-bold text-left"></div>
              {salaryBreakup.earnings?.map((item, index) => (
                <div className="flex flex-row gap-4" key={index}>
                  <div className="flex-1">{item.type_name}</div>
                  <div className="flex-1 text-right">AED {item.amount}</div>
                </div>
              ))}
              <div className="flex flex-row font-bold">
                <div className="flex-1 mb-4 text-lg font-medium text-black">
                  Total Salary in AED
                </div>
                <div className="flex-1 text-right">
                  AED {salaryBreakup.totalEarnings}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
        <div className="grid col-span-2 grid-rows-1 gap-4 mb-4 md:grid-rows-2">
          <SalarySummary
            joiningDate={employeeData?.joining_date}
            salaryType={payrollDetails?.salary_type}
            payoutPeriod={payrollDetails?.payout_period}
            lastRevisedDate={latestApprovedSalaryRevision?.last_revised_date}
            previousCTC={latestApprovedSalaryRevision?.previous_salary}
            currentCTC={latestApprovedSalaryRevision?.new_salary}
          />
          <Card className="mb-4 h-fit">
            <CardHeader>
              <CardTitle className="text-plum-900">PaySlips</CardTitle>
            </CardHeader>
            <CardContent className="flex items-center justify-between">
              <Select>
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Pick a month" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="jan">January</SelectItem>
                  <SelectItem value="feb">February</SelectItem>
                  <SelectItem value="mar">March</SelectItem>
                </SelectContent>
              </Select>
              <Button
                variant="secondary"
                onClick={() =>
                  navigate(`/payslip/${id}?employeeID=${employeeID}`)
                }
              >
                Download Slip
              </Button>
            </CardContent>
          </Card>
        </div>
      </div>

      <Card>
        <CardHeader className="flex flex-row items-center justify-between w-full">
          <CardTitle className="text-plum-900">Salary Revisions</CardTitle>
          <RevisedSalarySheet
            payrollID={id}
            state={"create"}
            onClose={onClose}
            previousCTC={latestApprovedSalaryRevision?.new_salary}
          />
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-3 gap-4 mb-4">
            {[
              { status: "Approved", count: approvedRevisions },
              { status: "Pending", count: pendingRevisions },
              { status: "Rejected", count: rejectedRevisions },
            ].map(({ status, count }, index) => (
              <div key={index}>
                <div className="flex flex-row items-center justify-between pb-2 space-y-0">
                  <div className="text-base font-medium text-black">
                    {status} Revision
                  </div>
                </div>
                <div>
                  <div
                    className={`text-2xl font-bold text-plum-900 ${
                      status === "Rejected" ? "text-red-600" : ""
                    }`}
                  >
                    {count}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex mb-4 space-x-4">
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  className="w-[240px] justify-start text-left font-normal"
                >
                  <CalendarIcon className="w-4 h-4 mr-2" />
                  {date ? format(date, "PPP") : <span>Pick a date</span>}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                  mode="single"
                  selected={date}
                  onSelect={setDate}
                  initialFocus
                />
              </PopoverContent>
            </Popover>
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  className="w-[240px] justify-start text-left font-normal"
                >
                  <Filter className="w-4 h-4 mr-2" />
                  <span>Revision Status</span>
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-[200px] p-0">
                <Command>
                  <CommandInput
                    placeholder="Search status..."
                    onValueChange={(value) => {}}
                  />
                  <CommandList>
                    <CommandEmpty>No status found.</CommandEmpty>
                    <CommandGroup heading="Statuses">
                      <CommandItem>Approved</CommandItem>
                      <CommandItem>Pending</CommandItem>
                      <CommandItem>Rejected</CommandItem>
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  className="w-[240px] justify-start text-left font-normal"
                >
                  <Filter className="w-4 h-4 mr-2" />
                  <span>Revision Letter</span>
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-[200px] p-0">
                <Command>
                  <CommandInput placeholder="Search letter status..." />
                  <CommandList>
                    <CommandEmpty>No status found.</CommandEmpty>
                    <CommandGroup heading="Letter Statuses">
                      <CommandItem>Issued</CommandItem>
                      <CommandItem>Not Issued</CommandItem>
                      <CommandItem>Draft</CommandItem>
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
          </div>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Revised CTC</TableHead>
                <TableHead>Previous CTC</TableHead>
                <TableHead>Last Revised Date</TableHead>
                <TableHead>Revision Status</TableHead>
                <TableHead>Revision Letter</TableHead>
                <TableHead>Reason for Revision</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {salaryRevisions?.map((revision, index) => (
                <TableRow
                  key={index}
                  onClick={() => {
                    handleSalaryRevisionClicked(revision);
                  }}
                  className="cursor-pointer"
                >
                  <TableCell>AED {revision?.new_salary}</TableCell>
                  <TableCell>AED {revision?.previous_salary}</TableCell>
                  <TableCell>{revision?.last_revised_date}</TableCell>
                  <TableCell>{revision?.revision_status}</TableCell>
                  <TableCell>
                    <span
                      className={`bg-${
                        revision.revision_letter === "Issued"
                          ? "green"
                          : revision.revision_letter === "Not Issued"
                          ? "red"
                          : "blue"
                      }-100 text-${
                        revision.revision_letter === "Issued"
                          ? "green"
                          : revision.revision_letter === "Not Issued"
                          ? "red"
                          : "blue"
                      }-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded`}
                    >
                      {revision.revision_letter}
                    </span>
                  </TableCell>
                  <TableCell>{revision.notes}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
}

function SalarySummary({
  joiningDate,
  salaryType,
  payoutPeriod,
  lastRevisedDate,
  previousCTC,
  currentCTC,
}) {
  return (
    <Card className="w-full ">
      <CardHeader>
        <CardTitle className="text-2xl font-semibold text-plum-900">
          Salary Summary
        </CardTitle>
      </CardHeader>
      <CardContent>
        <h3 className="mb-4 text-lg font-semibold text-black">Details</h3>
        <div className="grid grid-cols-2 gap-y-4">
          <div className="space-y-4">
            <div className="flex flex-row gap-6">
              <p className="">Joining Date</p>
              <p className="font-medium text-black">{joiningDate}</p>
            </div>
            <div className="flex flex-row gap-6">
              <p className="">Experience</p>
              <p className="font-medium text-black">{getExperience(joiningDate)} </p>
            </div>
            <div className="flex flex-row gap-6">
              <p className="">Salary Type</p>
              <p className="font-medium text-black">{salaryType}</p>
            </div>
            <div className="flex flex-row gap-6">
              <p className="">Payout Period</p>
              <p className="font-medium text-black">{payoutPeriod}</p>
            </div>
          </div>
          <div className="space-y-4">
            <div className="flex flex-row gap-6">
              <p className="">Last Revised Date</p>
              <p className="font-medium text-black">{lastRevisedDate}</p>
            </div>
            <div className="flex flex-row gap-6">
              <p className="">Previous CTC</p>
              <p className="font-medium text-black">{previousCTC}</p>
            </div>
            <div className="flex flex-row gap-6">
              <p className="">Current CTC</p>
              <p className="font-medium text-black">{currentCTC}</p>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
